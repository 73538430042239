import '@zenlayer/zen-design/esm/style/themes/index.css';
import { createRoot } from 'react-dom/client';
import App from './App';
import './global.less';
// import { serviceLandingPerformance } from '@/components/performance';
import {
  addSysThemeChangeListener,
  initThemes,
} from '@zenlayer/zen-design/esm/hooks/useThemeSwitch';
import { getLocale } from '@zenlayer/zen-intl18';
import { setConfig } from '@zenlayer/zen-design/esm/hooks/useConfig';
import zhLocale from '@zenlayer/zen-design/esm/locale/zh';

(function bootstrap() {
  const locale = getLocale();
  if (locale === 'zh') {
    setConfig({ locale: zhLocale, dayjs: { localeName: 'zh' } });
  }
  initThemes({ themes: ['dark', 'light'] });
  addSysThemeChangeListener();

  const container = document.getElementById('root-status') as HTMLElement;
  createRoot(container).render(<App />);
})();
